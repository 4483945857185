import "../styles/login.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import UserController from "../services/user-controller";
import { InputOtp } from "primereact/inputotp";
import { Link, useNavigate } from "react-router-dom";
import { setToken } from "../utility";

export default function Login(props) {
	const { t } = useTranslation();
	const localizer = t;
	const [pageType, setPageType] = useState("login");
	const [isLoading, setIsLoading] = useState(false);
	const [referalCode, setReferalCode] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isVerifyCodeSent, setIsVerifyCodeSent] = useState(false);
	const [verifyCode, setVerifyCode] = useState("");
	const [canSendAgain, setCanSendAgain] = useState(false);
	const userController = new UserController();
	const navigate = useNavigate();

	useEffect(() => {
		getData();
	}, []);
	//
	const getData = async function () {
		try {
			let _referalCode = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				_referalCode = urlParams.get("r");
			}
			if (_referalCode) {
				setReferalCode(_referalCode);
				setPageType("register");
			}
		} catch (error) {
			alert(error.toString());
		}
	};

	//

	const login = async function () {
		try {
			setIsLoading(true);
			setErrorMessage("");
			const result = await userController.login(username, password);
			if (result.isSuccess === false) throw result.message;
			setToken(result.value);
			//navigate("/home", { replace: true });
			window.location.reload();
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
	};

	const register = async function () {
		try {
			setIsLoading(true);
			setErrorMessage("");
			const result = await userController.registerUser(username, referalCode);
			if (result.isSuccess === false) throw result.message;

			setIsVerifyCodeSent(true);
			setCanSendAgain(false);
			startTimer(60);
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
	};

	const send = async function () {
		try {
			setIsLoading(true);
			setErrorMessage("");
			const result = await userController.checkUser(username);
			if (result.isSuccess === false) throw result.message;

			setIsVerifyCodeSent(true);
			setCanSendAgain(false);
			startTimer(60);
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
	};

	const startTimer = function () {
		window.waitForResend = 120;
		if (window.timerInterval) clearInterval(window.timerInterval);
		window.timerInterval = setInterval(() => {
			if (!document.querySelector("button.send-again > b")) return;
			window.waitForResend--;
			document.querySelector("button.send-again > b").innerHTML =
				window.waitForResend.toString();
			if (window.waitForResend <= 0) {
				document.querySelector("button.send-again > b").innerHTML = "";
				clearInterval(window.timerInterval);
				setCanSendAgain(true);
			}
		}, 1000);
	};

	const confirm = async function (verifyCode) {
		try {
			if (verifyCode?.length < 4) return;
			setIsLoading(true);
			setErrorMessage("");
			setVerifyCode(verifyCode);
			const result = await userController.confirmUser(username, verifyCode);
			if (result.isSuccess === false) throw result.message;
			setToken(result.value);
			window.location.reload();
			//navigate("/home", { replace: true });
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
	};

	const editUsername = function () {
		setIsVerifyCodeSent(false);
	};

	return (
		<article className="login">
			{pageType === "register" && (
				<>
					<h2>ثبت نام در سایت</h2>
					{!isVerifyCodeSent && (
						<div>
							<div>
								<label htmlFor="username">شماره موبایل</label>
								<InputText
									id="username"
									className="w-full"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>

							<div>
								<label htmlFor="referalCode">کد معرف</label>
								<InputText
									id="referalCode"
									className="w-full"
									value={referalCode}
									onChange={(e) => setReferalCode(e.target.value)}
								/>
							</div>

							<Button
								className="mahak-btn w-100 mt-2"
								label="ثبت نام"
								onClick={() => register()}
								severity="success"
								loading={isLoading}
							/>
							<div className="or-otp">
								<span>یا</span>
							</div>

							<Button
								className="w-full mt-2"
								label="ورود به سایت"
								onClick={() => setPageType("login")}
								severity="secondary"
								loading={isLoading}
							/>
						</div>
					)}
				</>
			)}

			{pageType === "login" && (
				<>
					<h2>ورود به سایت</h2>

					{!isVerifyCodeSent && (
						<div>
							<div>
								<label htmlFor="username">شماره موبایل</label>
								<InputText
									id="username"
									className="w-full"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>

							<div>
								<label htmlFor="password">رمز عبور</label>
								<Password
									id="password"
									className="w-full"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									feedback={false}
									toggleMask
								/>
							</div>

							<Button
								className="mahak-btn w-100 mt-2"
								label="ورود با رمز عبور"
								onClick={() => login()}
								loading={isLoading}
								severity="success"
							/>

							<div className="or-otp">
								<span>یا</span>
							</div>

							<Button
								className="w-full mt-2"
								label="ورود با رمز یکبار مصرف"
								onClick={() => send()}
								loading={isLoading}
							/>

							<Button
								className="w-full mt-2"
								label="ثبت نام در سایت"
								onClick={() => setPageType("register")}
								severity="secondary"
								loading={isLoading}
							/>
						</div>
					)}
				</>
			)}

			{isVerifyCodeSent && (
				<>
					<div>کد امنیتی ارسال شده به شماره زیر را وارد کنید</div>
					<div>
						<b className="site-ltr">{username}</b>
					</div>
					<div className="flex">
						{/* <InputOtp
							value={verifyCode}
							onChange={(e) => confirm(e.value)}
						/> */}
						<InputText
							value={verifyCode}
							onChange={(e) => {
								setVerifyCode(e.target.value);
								confirm(e.target.value);
							}}
							maxLength={4}
							keyfilter="int"
						/>
					</div>

					<button
						className="p-button p-component p-button-text send-again"
						onClick={() => send()}
						disabled={!canSendAgain}
					>
						<span className="p-button-label p-c">ارسال مجدد</span>
						<b></b>
					</button>
					<Button
						label="تغییر شماره موبایل"
						icon="pi pi-file-edit"
						onClick={() => editUsername()}
						text
					/>
				</>
			)}

			{errorMessage && <div className="text-danger">{errorMessage}</div>}
		</article>
	);
}
