import "../styles/view-missions.css";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APP_BASE_URL, getToken } from "../utility";
import { Skeleton } from "primereact/skeleton";
import { PrimeIcons } from "primereact/api";

export default function ViewMissions() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const toast = useRef(null);
	//
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [missions, setMissions] = useState([]);
	const [mission, setMission] = useState({});
	//> useEffect
	useEffect(() => {
		getData();
	}, []);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			const missionsResult = await serviceController.getMissions();
			if (missionsResult.isSuccess === false) throw missionsResult.message;
			missionsResult.value.map((item) => {
				item.publishDate = new Date(item.publishDate);
				item.expireDate = new Date(item.expireDate);
				item.dataObject = JSON.parse(item.data);
			});

			setMissions(missionsResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> viewMission
	const viewMission = function (objMission) {
		setPageType("view");
		setMission(objMission);
	};
	//> runMission
	const runMission = function () {
		setPageType("list");
	};
	//> cancelMission
	const cancelMission = function () {
		setPageType("list");
	};
	//> addUserMission
	const addUserMission = async function () {
		try {
			if (mission.userStatus === 0 || mission.userStatus === 1) return;
			const userMissionsResult = await serviceController.addUserMission(
				mission.missionId
			);
			if (userMissionsResult.isSuccess === false)
				throw userMissionsResult.message;
			mission.userStatus = 1;
			setMission(mission);
			getData();
		} catch (error) {
			toast.current.show({
				severity: "warn",
				summary: localizer("Complete mission"),
				detail: error.toString(),
			});
		}
	};
	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="view-missions">
				{pageType === "list" && (
					<ul>
						{missions.map((item) => (
							<li>
								<a href="javascript:void(0);" onClick={() => viewMission(item)}>
									<i
										className={
											item.missionType === "video"
												? PrimeIcons.YOUTUBE
												: PrimeIcons.TELEGRAM
										}
									></i>
									<div>
										<h2>{item.title}</h2>
										<strong>
											<i className={PrimeIcons.WALLET}></i>
											{item.score}
											&nbsp; امتیاز
										</strong>
									</div>
									<i className={item.userStatus === 1 ? PrimeIcons.CHECK_CIRCLE : PrimeIcons.ANGLE_LEFT}></i>
								</a>
							</li>
						))}
					</ul>
				)}

				{pageType === "view" && (
					<div className="details">
						<div>
							<a
								className={PrimeIcons.TIMES}
								href="javascript:void(0);"
								onClick={() => cancelMission()}
							></a>
						</div>
						<i
							className={
								mission.missionType === "video"
									? PrimeIcons.YOUTUBE
									: PrimeIcons.TELEGRAM
							}
						></i>
						<h2>{mission.title}</h2>
						<p>{mission.notes}</p>
						{mission.missionType === "video" && (
							<>
								<video controls onPlay={() => addUserMission()}>
									<source
										type="video/mpeg"
										src={mission.dataObject?.videoUrl}
									/>
								</video>
								<audio controls onPlay={() => addUserMission()}>
									<source
										type="audio/mpeg"
										src={mission.dataObject?.voiceUrl}
									/>
								</audio>
							</>
						)}
						{mission.missionType === "link" && (
							<>
								<a
									href={mission.dataObject?.linkUrl}
									target="_blank"
									onClick={() => addUserMission()}
								>
									ورود
								</a>
							</>
						)}
						<strong>
							<i className={PrimeIcons.WALLET}></i>+{mission.score}
						</strong>
						{mission.userStatus === 1 && (
							<h3>شما امتیاز این ماموریت را دریافت کرده اید</h3>
						)}
						{mission.userStatus === 0 && <button>چک کردن امتیاز</button>}
					</div>
				)}
			</article>
		</>
	);
}
