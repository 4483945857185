import axios from "axios";
import { API_BASE_URL, getToken } from "../utility";

export default function UserController() {
	//> registerUser
	this.registerUser = async function (username, referalCode) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/RegisterUser`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					username: username,
					referalCode: referalCode,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> checkUser
	this.checkUser = async function (username) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/CheckUser`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					username: username,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> confirmUser
	this.confirmUser = async function (username, verifyCode) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/ConfirmUser`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					username: username,
					verifyCode: verifyCode,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> login
	this.login = async function (username, password) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/Login`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					username: username,
					password: password,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getProfile
	this.getProfile = async function () {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/GetProfile`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> changePassword
	this.changePassword = async function (
		currentPassword,
		newPassword,
		confirmNewPassword
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/ChangePassword`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					currentPassword: currentPassword,
					newPassword: newPassword,
					confirmNewPassword: confirmNewPassword,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> updateProfile
	this.updateProfile = async function (
		firstName,
		lastName,
		gender,
		birthDate,
		stateName,
		cityName
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}User/UpdateProfile`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					firstName: firstName,
					lastName: lastName,
					gender: gender,
					birthDate: new Date(birthDate),
					stateName: stateName,
					cityName: cityName,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> isAdminUser
	this.isAdminUser = async function () {
		let result = false;
		const token = getToken();
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/IsAdmin`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = false;
		}
		return result;
	};
	//>
}
