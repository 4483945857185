import "../styles/edit-missions.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APP_BASE_URL, getToken } from "../utility";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

export default function EditMissions() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(1);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [missionId, setMissionId] = useState("");
	const [missionType, setMissionType] = useState("");
	const [title, setTitle] = useState("");
	const [notes, setNotes] = useState("");
	const [data, setData] = useState("");
	const [status, setStatus] = useState("");
	const [score, setScore] = useState(0);
	const [publishDate, setPublishDate] = useState("");
	const [expireDate, setExpireDate] = useState("");
	//
	const [missions, setMissions] = useState([]);
	//> missionTypes
	const missionTypes = [
		{ value: "video", label: "ویدئو" },
		{ value: "link", label: "لینک" },
	];
	//> statuses
	const statuses = [
		{ value: 1, label: "فعال" },
		{ value: 0, label: "غیر فعال" },
	];

	//> useEffect
	useEffect(() => {
		getData();
	}, []);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			const missionsResult = await adminController.getMissions(first, 12);
			if (missionsResult.isSuccess === false) throw missionsResult.message;
			missionsResult.value.map((item) => {
				item.publishDate = new Date(item.publishDate);
				item.expireDate = new Date(item.expireDate);
			});

			setMissions(missionsResult.value);
			setTotalRecords(missionsResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> addMission
	const addMission = function () {
		setPageType("edit");
		setMissionId(null);
		setMissionType("");
		setTitle("");
		setNotes("");
		setData("");
		setStatus("");
		setScore("");
		setPublishDate(null);
		setExpireDate(null);
	};
	//> editMission
	const editMission = function (objMission) {
		setPageType("edit");
		setMissionId(objMission.missionId);
		setMissionType(objMission.missionType);
		setTitle(objMission.title);
		setNotes(objMission.notes);
		setData(objMission.data);
		setStatus(objMission.status);
		setScore(objMission.score);
		setPublishDate(objMission.publishDate);
		setExpireDate(objMission.expireDate);
	};
	//> updateMission
	const updateMission = async function () {
		try {
			let errors = [];
			if (!missionType) errors.push(localizer("Enter mission type"));
			if (!title) errors.push(localizer("Enter title"));
			if (!data) errors.push(localizer("Enter data"));
			if (!status) errors.push(localizer("Select status"));
			if (!score || score <= 0) errors.push(localizer("Enter score"));
			if (!publishDate) errors.push(localizer("Enter publish date"));
			if (!expireDate) errors.push(localizer("Enter expire date"));

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: localizer("Update profile"),
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateMissionResult = await adminController.updateMission(
				missionId,
				missionType,
				title,
				notes,
				data,
				status,
				score,
				publishDate,
				expireDate
			);
			if (updateMissionResult.isSuccess === false)
				throw updateMissionResult.message;

			toast.current.show({
				severity: "success",
				summary: localizer("Update mission"),
				detail: localizer("Mission successfully updated"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> cancelMission
	const cancelMission = function () {
		setPageType("list");
	};
	//>createDefaultData
	const createDefaultData = function () {
		let _data = {};
		if (missionType === "video") _data = { videoUrl: "", voiceUrl: "" };
		else _data = { linkUrl: "" };
		setData(JSON.stringify(_data,null, "\t"));
	};
	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="missions">
				<div>
					<Button
						label="ثبت ماموریت جدید"
						icon={PrimeIcons.PLUS_CIRCLE}
						onClick={() => addMission()}
					/>
				</div>

				{pageType === "list" && (
					<>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>عنوان</th>
									<th>نوع ماموریت</th>
									<th>شرح</th>
									<th>امتیاز</th>
									<th>تاریخ انتشار</th>
									<th>تاریخ انقضا</th>
									<th>وضعیت</th>
								</tr>
							</thead>
							<tbody>
								{missions.map((item) => (
									<tr>
										<td>#</td>
										<td>
											<a href="javascript:void(0);" onClick={()=> editMission(item)}>{item.title}</a>
										</td>
										<td>{item.missionType}</td>
										<td>{item.notes}</td>
										<td>{item.score}</td>
										<td>
											{item.publishDate.toLocaleString("fa-IR", {
												dateStyle: "short",
											})}
										</td>
										<td>
											{item.expireDate.toLocaleString("fa-IR", {
												dateStyle: "short",
											})}
										</td>
										<td>{item.status === 1 ? "فعال" : "غیر فعال"}</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="grid">
						<FloatLabel className="col-6">
							<Dropdown
								id="missionType"
								className="w-full"
								value={missionType}
								options={missionTypes}
								optionValue="value"
								optionLabel="label"
								onChange={(e) => setMissionType(e.target.value)}
							/>
							<label htmlFor="missionType"> نوع ماموریت</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<InputText
								id="title"
								className="w-full"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<label htmlFor="title"> عنوان ماموریت</label>
						</FloatLabel>
						<FloatLabel className="col-12">
							<InputTextarea
								id="notes"
								className="w-full"
								value={notes}
								onChange={(e) => setNotes(e.target.value)}
							/>
							<label htmlFor="notes"> شرح ماموریت</label>
						</FloatLabel>
						<FloatLabel className="col-12">
							<div className="p-inputgroup flex-1">
								<button
									className="p-inputgroup-addon"
									onClick={() => createDefaultData()}
								>
									<i className={PrimeIcons.TH_LARGE}></i>
								</button>
								<InputTextarea
									id="data"
									className="w-full"
									value={data}
									onChange={(e) => setData(e.target.value)}
									autoResize
								/>
							</div>
							<label htmlFor="data"> جزییات فنی</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<Dropdown
								id="status"
								className="w-full"
								value={status}
								options={statuses}
								optionValue="value"
								optionLabel="label"
								onChange={(e) => setStatus(e.target.value)}
							/>
							<label htmlFor="status"> وضعیت</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<InputText
								id="score"
								className="w-full"
								value={score}
								onChange={(e) => setScore(e.target.value)}
							/>
							<label htmlFor="score"> امتیاز دریافتی</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<DatePicker
								value={publishDate}
								onChange={(value) => setPublishDate(value)}
								calendar={persian}
								locale={persian_fa}
							/>
							<label htmlFor="publishDate"> تاریخ انتشار</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<DatePicker
								value={expireDate}
								onChange={(value) => setExpireDate(value)}
								calendar={persian}
								locale={persian_fa}
							/>
							<label htmlFor="expireDate"> تاریخ انقضا</label>
						</FloatLabel>

						<div className="col-12 buttons">
							<Button
								label="ثبت ماموریت"
								onClick={() => updateMission()}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelMission()}
								severity="danger"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
