import "../styles/admin-tree.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APP_BASE_URL, getToken } from "../utility";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Tree } from "primereact/tree";

export default function AdminTree() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	//
	const [profile, setProfile] = useState([]);
	const [users, setUsers] = useState([]);
	//
	useEffect(() => {
		getData();
	}, []);
	//
	const getData = async function () {
		try {
			let _userId = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				_userId = urlParams.get("userId");
			}

			setIsLoading(true);
			const profileResult = await adminController.getUserProfile(_userId);
			if (profileResult.isSuccess === false) throw profileResult.message;
			setProfile(profileResult.value);
			//>
			let users = await getChildren(_userId);
			let _users = [];
			users.map((item) => {
				_users.push({
					key: item.userId,
					label: item.fullName,
					leaf: item.hasChildren === false ? true : false,
					icon: PrimeIcons.USER,
				});
			});

			setUsers(_users);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> getChildren
	const getChildren = async function (parentId) {
		const childrenResult = await adminController.getChildren(parentId);
		if (childrenResult.isSuccess === false) throw childrenResult.message;
		return childrenResult.value;
	};
	//> loadChildren
	const loadChildren = async function (event) {
		if (event.node.children) return;
		setIsLoading(true);

		let node = { ...event.node };

		node.children = [];

		let children = await getChildren(event.node.key);
		children.map((item) => {
			node.children.push({
				key: item.userId,
				label: item.fullName,
				leaf: item.hasChildren === false ? true : false,
				icon: PrimeIcons.USER,
			});
		});

		let value = [...users];

		findChildren(value,event.node.key).children = node.children;

		setUsers(value);
		setIsLoading(false);
	};

	//> findChildren
	const findChildren = function (children, key) {
		let findItem = children.find((item) => item.key === key);
		if (!findItem) {
			for (let i = 0; i < children.length; i++) {
				if (children[i].children) findItem = findChildren(children[i].children,key);
				if (findItem) break;
			}
		}
		return findItem;
	};
	//
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="admin-tree">
				<div className="profile">
					<div className="summary">
						<img src={profile.imageUrl} />
						<div>
							<h2>{profile.fullName}</h2>
							<h3>{profile.mobile}</h3>
						</div>
						<ul>
							<li>
								<strong>{profile.totalDays}</strong>
								<span>روز عضویت</span>
							</li>
							<li>
								<strong>{profile.totalMembers}</strong>
								<span>زیر مجموعه</span>
							</li>
							<li>
								<strong>{profile.totalScore}</strong>
								<span>امتیاز</span>
							</li>
						</ul>
					</div>

					<div className="info">
						<ul>
							<li>
								<label>نام</label>
								<span>{profile.firstName}</span>
							</li>
							<li>
								<label>نام خانوادگی</label>
								<span>{profile.lastName}</span>
							</li>
							<li>
								<label>جنسیت</label>
								<span>
									{profile.gender === true
										? "آقا"
										: profile.gender === false
										? "خانم"
										: "--"}
								</span>
							</li>
							<li>
								<label>تاریخ تولد</label>
								<span>
									{profile.birthDate
										? new Date(profile.birthDate).toLocaleString("fa-IR", {
												dateStyle: "short",
										  })
										: ""}
								</span>
							</li>
							<li>
								<label>استان</label>
								<span>{profile.stateName}</span>
							</li>
							<li>
								<label>شهر</label>
								<span>{profile.cityName}</span>
							</li>
						</ul>
					</div>
				</div>

				<Tree value={users} onExpand={loadChildren} loading={isLoading} />
			</article>
		</>
	);
}
