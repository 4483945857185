import "../styles/profile.css";
import { useTranslation } from "react-i18next";
import UserController from "../services/user-controller";
import ServiceController from "../services/service-controller";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { APP_BASE_URL, getToken } from "../utility";
import { PrimeIcons } from "primereact/api";
import { InputMask } from "primereact/inputmask";

export default function Profile() {
	const { t } = useTranslation();
	const localizer = t;
	const userController = new UserController();
	const serviceController = new ServiceController();
	const toast = useRef(null);
	//
	const [profile, setProfile] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [friendMobile, setFriendMobile] = useState("");
	const [friendName, setFriendName] = useState("");
	const [isInviting, setIsInviting] = useState("");
	//
	useEffect(() => {
		getData();
	}, []);
	//
	const getData = async function () {
		try {
			var profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false) throw profileResult.message;

			setProfile(profileResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> copyText
	const copyText = function (value) {
		let copyText = document.querySelector("input#copy");
		//
		copyText.select();
		copyText.value = value;
		copyText.setSelectionRange(0, 99999);
		//
		navigator.clipboard.writeText(copyText.value);
		toast.current.show({
			severity: "info",
			summary: "کد با موفقیت کپی شد",
		});
	};
	//> sendInviteLink
	const sendInviteLink = async function () {
		try {
			let errors = [];
			if (!friendMobile) errors.push(localizer("Enter freind mobile"));
			if (!friendName) errors.push(localizer("Enter freind name"));

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: localizer("Invite send link"),
						detail: item,
					});
				});
				return;
			}
			//
			setIsInviting(true);
			var sednResult = await serviceController.sendInviteLink(friendMobile,friendName);
			if (sednResult.isSuccess === false) throw sednResult.message;

			toast.current.show({
				severity: "success",
				summary: localizer("Invite link successfully sent to your freind"),
			});

			setFriendMobile("");
			setFriendName("");
			
		} catch (error) {
			alert(error.toString());
		}
		setIsInviting(false);
	};

	//
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="profile">
				<div className="summary">
					<img src={profile.imageUrl} />
					<div>
						<h2>{profile.fullName}</h2>
						<h3>{profile.mobile}</h3>
					</div>
					<ul>
						<li>
							<strong>{profile.totalDays}</strong>
							<span>روز عضویت</span>
						</li>
						<li>
							<strong>{profile.totalMembers}</strong>
							<span>زیر مجموعه</span>
						</li>
						<li>
							<strong>{profile.totalScore}</strong>
							<span>امتیاز</span>
						</li>
					</ul>
				</div>

				<div className="info">
					<ul>
						<li>
							<label>نام</label>
							<span>{profile.firstName}</span>
						</li>
						<li>
							<label>نام خانوادگی</label>
							<span>{profile.lastName}</span>
						</li>
						<li>
							<label>جنسیت</label>
							<span>
								{profile.gender === true
									? "آقا"
									: profile.gender === false
									? "خانم"
									: "--"}
							</span>
						</li>
						<li>
							<label>تاریخ تولد</label>
							<span>
								{profile.birthDate
									? new Date(profile.birthDate).toLocaleString("fa-IR", {
											dateStyle: "short",
									  })
									: ""}
							</span>
						</li>
						<li>
							<label>استان</label>
							<span>{profile.stateName}</span>
						</li>
						<li>
							<label>شهر</label>
							<span>{profile.cityName}</span>
						</li>
					</ul>
					<div className="buttons">
						<a href="/#/edit-profile">ویرایش پروفایل</a>
						<a href="/#/change-password">تغییر رمز عبور</a>
					</div>
				</div>

				<div className="referal">
					<div>
						<label>کد معرفی شما</label>
						<strong>{profile.referalCode}</strong>
						<a
							className={PrimeIcons.COPY}
							onClick={() => copyText(profile.referalCode)}
						></a>
					</div>
					<div>
						<label>لینک معرفی شما</label>
						<strong>
							{APP_BASE_URL}?r={profile.referalCode}
						</strong>
						<a
							className={PrimeIcons.COPY}
							onClick={() =>
								copyText(APP_BASE_URL + "/?r=" + profile.referalCode)
							}
						></a>
					</div>
				</div>

				<div className="invite">
					<h2>ارسال لینک معرفی به دوستان</h2>
					<div>
						<label>شماره موبایل</label>
						<InputMask
							value={friendMobile}
							className="ltr"
							onChange={(e) => setFriendMobile(e.target.value)}
							mask="09999999999"
							slotChar="#"
						/>
					</div>
					<div>
						<label>نام</label>
						<InputText
							value={friendName}
							onChange={(e) => setFriendName(e.target.value)}
							maxLength={20}
						/>
					</div>
					<div>
						<label></label>
					<Button  label="ارسال لینک دعوت نامه" onClick={() => sendInviteLink()} loading={isInviting} />
					</div>
				</div>

				<div style={{ display: "none" }} >
					<input type="text" id="copy" />
				</div>
			</article>
		</>
	);
}
