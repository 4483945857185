import "../styles/edit-profile.css";
import { useTranslation } from "react-i18next";
import UserController from "../services/user-controller";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { getToken } from "../utility";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import ServiceController from "../services/service-controller";

export default function EditProfile() {
	const { t } = useTranslation();
	const localizer = t;
	const userController = new UserController();
	const serviceController = new ServiceController();
	const toast = useRef(null);
	//
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	//
	const [profile, setProfile] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState(null);
	const [birthDate, setBirthDate] = useState(null);
	const [stateName, setStateName] = useState("");
	const [cityName, setCityName] = useState("");
	//
	useEffect(() => {
		getData();
	}, []);
	//
	const genders = [
		{ name: "--", value: null },
		{ name: "آقا", value: true },
		{ name: "خانم", value: true },
	];
	//
	const getData = async function () {
		try {
			var profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false) throw profileResult.message;
			setProfile(profileResult.value);
			setFirstName(profileResult.value.firstName);
			setLastName(profileResult.value.lastName);
			setGender(profileResult.value.gender);
			if (profileResult.value.birthDate)
				setBirthDate(new Date(profileResult.value.birthDate));
			else setBirthDate(null);
			//
			let statesResult = await serviceController.getStates();
			if (statesResult.isSuccess === false) throw statesResult.message;
			setStates(statesResult.value);
			setStateName(profileResult.value.stateName);
			//
			let citiesResult = await serviceController.getCities(
				profileResult.value.stateName
			);
			if (citiesResult.isSuccess === false) throw citiesResult.message;
			setCities(citiesResult.value);
			//
			setCityName(profileResult.value.cityName);
		} catch (error) {
			alert(error.toString());
		}
	};
	//
	const getCities = async function (stateName) {
		try {
			setStateName(stateName);
			let citiesResult = await serviceController.getCities(
				stateName
			);
			if (citiesResult.isSuccess === false) throw citiesResult.message;
			setCities(citiesResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//
	const updateProfile = async function () {
		try {
			let errors = [];
			if (!firstName) errors.push(localizer("Enter new first name"));
			if (!lastName) errors.push(localizer("Enter last name"));
			if (!gender) errors.push(localizer("Enter gender"));
			if (!birthDate) errors.push(localizer("Enter birth date"));
			if (!stateName) errors.push(localizer("Enter state name"));
			if (!cityName) errors.push(localizer("Enter city name"));

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: localizer("Update profile"),
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var changePasswordResult = await userController.updateProfile(
				firstName,
				lastName,
				gender,
				birthDate,
				stateName,
				cityName
			);
			if (changePasswordResult.isSuccess === false)
				throw changePasswordResult.message;

			toast.current.show({
				severity: "success",
				summary: localizer("Update profile"),
				detail: localizer("Profile successfully updated"),
			});
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<article className="edit-profile">
			<h2>ویرایش پروفایل</h2>
			<div className="grid">
				<Toast ref={toast} />
				<FloatLabel className="col-6">
					<InputText
						id="firstName"
						className="w-full"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<label htmlFor="firstName"> نام</label>
				</FloatLabel>

				<FloatLabel className="col-6">
					<InputText
						id="lastName"
						className="w-full"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<label htmlFor="fullName">نام خانوادگی</label>
				</FloatLabel>

				<FloatLabel className="col-6">
					<Dropdown
						id="gender"
						className="w-full"
						value={gender}
						options={genders}
						optionValue="value"
						optionLabel="name"
						onChange={(e) => setGender(e.value)}
					/>
					<label htmlFor="fullName">جنسیت</label>
				</FloatLabel>

				<FloatLabel className="col-6">
					<DatePicker
						value={birthDate}
						onChange={(value) => setBirthDate(value)}
						calendar={persian}
						locale={persian_fa}
					/>
					<label htmlFor="birthDate">تاریخ تولد</label>
				</FloatLabel>

				<FloatLabel className="col-6">
					<Dropdown
						id="stateName"
						className="w-full"
						value={stateName}
						onChange={(e) => getCities(e.value)}
						options={states}
					/>
					<label htmlFor="stateName">استان</label>
				</FloatLabel>

				<FloatLabel className="col-6">
					<Dropdown
						id="cityName"
						className="w-full"
						value={cityName}
						onChange={(e) => setCityName(e.value)}
						options={cities}
					/>
					<label htmlFor="cityName">شهرستان</label>
				</FloatLabel>

				<div className="col-6"></div>
				<Button
					className="col-12"
					label="ثبت اطلاعات"
					onClick={() => updateProfile()}
					severity="success"
					loading={isSaving}
				/>
			</div>
		</article>
	);
}
