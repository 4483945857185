import "../styles/users.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APP_BASE_URL, getToken } from "../utility";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";

export default function Users() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(1);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isDownloading, setIsDownloading] = useState(0);
	//
	const [users, setUsers] = useState([]);
	//
	useEffect(() => {
		getData();
	}, []);
	//
	const getData = async function () {
		try {
			setIsLoading(true);
			const usersResult = await adminController.getUsers(first, 12);
			if (usersResult.isSuccess === false) throw usersResult.message;
			usersResult.value.map((item) => {
				item.createdOn = new Date(item.createdOn);
				if (item.birthDate) {
					item.age =
						new Date().getFullYear() - new Date(item.birthDate).getFullYear();
				}
			});

			setUsers(usersResult.value);
			setTotalRecords(usersResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> downloadExcel
	const downloadExcel = async function () {
		try {
			setIsDownloading(true);
			const exportResult = await adminController.exportUsers();
			if(exportResult.isSuccess === false)
				throw exportResult.message;
			document.querySelector("#excel").setAttribute("href", "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + exportResult.value);
			document.querySelector("#excel").click();
		} catch (error) {
			alert(error.toString());
		}
		setIsDownloading(false);
	};

	//
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="users">
				<div>
					<Button
						label="خروجی اکسل"
						icon={PrimeIcons.FILE_EXCEL}
						onClick={() => downloadExcel()}
						loading={isDownloading}
					/>
					<a id="excel" style={{ display: "none" }} target="_blank" />
				</div>
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th>موبایل</th>
							<th>نام</th>
							<th>جنسیت</th>
							<th>سن</th>
							<th>استان</th>
							<th>شهر</th>
							<th>امتیاز</th>
							<th>تعداد</th>
							<th>تاریخ ثبت</th>
						</tr>
					</thead>
					<tbody>
						{users.map((item) => (
							<tr>
								<td>#</td>
								<td>
									<a href={`/#/admin-tree/?userId=${item.userId}`} >{item.mobile}</a>
								</td>
								<td>{item.fullName}</td>
								<td>
									{item.gender === true
										? "آقا"
										: item.gender === false
										? "خانم"
										: "--"}
								</td>
								<td>{item.age}</td>
								<td>{item.stateName}</td>
								<td>{item.cityName}</td>
								<td>{item.totalScore}</td>
								<td>{item.totalMembers}</td>
								<td>
									{item.createdOn.toLocaleString("fa-IR", {
										dateStyle: "short",
									})}
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<Paginator
					first={first}
					rows={rows}
					totalRecords={totalRecords}
					onPageChange={onPageChange}
				/>
			</article>
		</>
	);
}
