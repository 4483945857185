import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
//
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrimeIcons } from "primereact/api";

//
import Home from "./views/home.js";
import AboutUs from "./views/about-us.js";
import ContactUs from "./views/contact-us.js";
import Help from "./views/help.js";
import Login from "./views/login.js";
import Profile from "./views/profile.js";
import EditProfile from "./views/edit-profile.js";
import ChangePassword from "./views/change-password.js";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
//
import { getToken, setToken } from "./utility";
import { useEffect, useState } from "react";
import UserController from "./services/user-controller.js";
import Admin from "./views/admin.js";
import Users from "./views/users.js";
import AdminTree from "./views/admin-tree.js";
import EditMissions from "./views/edit-missions.js";
import ViewMissions from "./views/view-missions.js";

function App() {
	const userController = new UserController();
	const [hasMenu, setHasMenu] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const token = getToken();
	//>  useEffect
	useEffect(() => {
		userController.isAdminUser().then((value) => {
			setIsAdmin(value);
		});
	}, []);

	//> confirmSignOut
	const confirmSignOut = function () {
		confirmDialog({
			message: "آیا مطمئن به خروج هستید؟",
			header: "تایید خروج",
			icon: "pi pi-exclamation-triangle",
			defaultFocus: "accept",
			acceptLabel: "بله",
			rejectLabel: "نه",
			accept,
			reject,
		});
	};

	const accept = function () {
		sessionStorage.clear();
		window.location = "/";
	};

	const reject = function () {};
	//>
	return (
		<div className="main">
			<ConfirmDialog />
			<header className="head">
				<a
					className={hasMenu ? PrimeIcons.TIMES : PrimeIcons.BARS}
					onClick={() => setHasMenu(!hasMenu)}
				></a>
				<ul className={hasMenu ? "is-open" : ""}>
					<li>
						<a href="/#/" onClick={() => setHasMenu(false)}>
							صفحه اصلی
						</a>
					</li>
					<li>
						<a href="/#/about-us/" onClick={() => setHasMenu(false)}>
							درباره ما
						</a>
					</li>
					<li>
						<a href="/#/contact-us" onClick={() => setHasMenu(false)}>
							تماس با ما
						</a>
					</li>
					{getToken() && (
						<>
							<li>
								<a href="/#/profile" onClick={() => setHasMenu(false)}>
									پروفایل
								</a>
							</li>
							{isAdmin === true && (
								<li>
									<a href="/#/admin" onClick={() => setHasMenu(false)}>
										مدیریت
									</a>
								</li>
							)}

							<li>
								<a href="javascript:void(0);" onClick={() => confirmSignOut()}>
									خروج
								</a>
							</li>
						</>
					)}
				</ul>
			</header>
			<div className="content">
				<div>
					<Routes>
						<Route path="/" Component={Home} />
						<Route path="/home" Component={Home} />
						<Route path="/profile" Component={Profile} />
						<Route path="/edit-profile" Component={EditProfile} />
						<Route path="/change-password" Component={ChangePassword} />
						<Route path="/about-us" Component={AboutUs} />
						<Route path="/contact-us" Component={ContactUs} />
						<Route path="/help" Component={Help} />
						<Route path="/admin" Component={Admin} />
						<Route path="/users" Component={Users} />
						<Route path="/admin-tree" Component={AdminTree} />
						<Route path="/edit-missions" Component={EditMissions} />
						<Route path="/view-missions" Component={ViewMissions} />
					</Routes>
				</div>
			</div>
			<footer>
				<div>
					<div className="about">
						<h2>درباره ما</h2>
						<p>
							دکتر مسعود پزشکیان کاندیدای ریاست جمهوری
							<br />
							وزیر بهداشت و درمان دولت دوم آقای خاتمی
							<br />
							نایب رئیس مجلس شورای اسلامی
							<br />
							نماینده تبریز، آذرشهر و اسکو
						</p>
					</div>
					<div className="news">
						<h2>آخرین خبر ها</h2>
						<ul>
							<li>
								<a>
									<strong>خبر 1</strong>
									<p>خلاصه خبر 1</p>
								</a>
							</li>
							<li>
								<a>
									<strong>خبر 2</strong>
									<p>خلاصه خبر 2</p>
								</a>
							</li>
							<li>
								<a>
									<strong>خبر3</strong>
									<p>خلاصه خبر 3</p>
								</a>
							</li>
						</ul>
					</div>
					<div className="links">
						<h2>لینک های مفید</h2>
						<ul>
							<li>
								<a>
									<strong>لینک</strong>
									<i className={PrimeIcons.ANGLE_LEFT}></i>
								</a>
							</li>
							<li>
								<a>
									<strong>لینک</strong>
									<i className={PrimeIcons.ANGLE_LEFT}></i>
								</a>
							</li>
							<li>
								<a>
									<strong>لینک</strong>
									<i className={PrimeIcons.ANGLE_LEFT}></i>
								</a>
							</li>
							<li>
								<a>
									<strong>لینک</strong>
									<i className={PrimeIcons.ANGLE_LEFT}></i>
								</a>
							</li>
							<li>
								<a>
									<strong>لینک</strong>
									<i className={PrimeIcons.ANGLE_LEFT}></i>
								</a>
							</li>
						</ul>
					</div>
					<div className="contact">
						<h2>تماس با ما</h2>
						<ul>
							<li>
								<i className={PrimeIcons.MAP_MARKER}></i>
								<div>آدرس</div>
							</li>
							<li>
								<i className={PrimeIcons.PHONE}></i>
								<div>
									<a>تلفن 1</a>
									<a>تلفن 2</a>
								</div>
							</li>
							<li>
								<i className={PrimeIcons.GLOBE}></i>
								<div>
									<a>info@yourdomain.com</a>
									<a>yourdomain.com</a>
								</div>
							</li>
						</ul>
					</div>
				</div>

				<div className="copyright">
					<p>@2024 All Right Resereved</p>
					<ul>
						<li>
							<a>
								<i className={PrimeIcons.LINKEDIN}></i>
							</a>
						</li>
						<li>
							<a>
								<i className={PrimeIcons.TWITTER}></i>
							</a>
						</li>
						<li>
							<a>
								<i className={PrimeIcons.FACEBOOK}></i>
							</a>
						</li>
						<li>
							<a href="https://t.me/epvech" target="_blank">
								<i className={PrimeIcons.TELEGRAM}></i>
							</a>
						</li>
						<li>
							<a>
								<i className={PrimeIcons.INSTAGRAM}></i>
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</div>
	);
}

export default App;
