import { getToken } from "../utility";
import Login from "./login.js";
import ViewMissions from "./view-missions.js";

export default function Home() {
	if (!getToken()) {
		return <Login />;
	}
	return <ViewMissions />;
}
