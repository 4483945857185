import "../styles/change-password.css";
import { useTranslation } from "react-i18next";
import UserController from "../services/user-controller";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { getToken } from "../utility";

export default function ChangePassword() {
	const { t } = useTranslation();
	const localizer = t;
	const userController = new UserController();
	const toast = useRef(null);
	//
	const [profile, setProfile] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	//
	useEffect(() => {
		getData();
	}, []);
	//
	const getData = async function () {
		try {
			var profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false) throw profileResult.message;

			setProfile(profileResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//
	const changePassword = async function () {
		try {
			let errors = [];
			if (!newPassword) errors.push(localizer("Enter new password"));
			if (!confirmNewPassword)
				errors.push(localizer("Enter confrim new password"));
			if (
				newPassword &&
				confirmNewPassword &&
				newPassword !== confirmNewPassword
			)
				errors.push(
					localizer("Password and confrim new password must be equal")
				);
			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: localizer("Change password"),
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var changePasswordResult = await userController.changePassword(
				currentPassword,
				newPassword,
				confirmNewPassword
			);
			if (changePasswordResult.isSuccess === false)
				throw changePasswordResult.message;

			toast.current.show({
				severity: "success",
				summary: localizer("Change password"),
				detail: localizer("Password successfull changed"),
			});

			setCurrentPassword("");
			setNewPassword("");
			setCurrentPassword("");
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<article className="change-password">
			<h2>تغییر رمز عبور</h2>
			<Card>
				<div className="grid">
					<Toast ref={toast} />
					<FloatLabel className="col-6">
						<InputText
							id="username"
							className="w-full"
							value={profile.username}
							readOnly
						/>
						<label htmlFor="username">{localizer("Username")}</label>
					</FloatLabel>

					<FloatLabel className="col-6">
						<InputText
							id="fullName"
							className="w-full"
							value={profile.fullName}
							readOnly
						/>
						<label htmlFor="fullName">{localizer("Display name")}</label>
					</FloatLabel>

					<FloatLabel className="col-6">
						<Password
							className="w-full"
							inputId="currentPassword"
							value={currentPassword}
							onChange={(e) => setCurrentPassword(e.target.value)}
							toggleMask
						/>
						<label htmlFor="currentPassword">
							{localizer("Current password")}
						</label>
					</FloatLabel>
					<div className="col-6"></div>

					<FloatLabel className="col-6">
						<Password
							className="w-full"
							inputId="newPassword"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							toggleMask
							promptLabel={localizer("Choose a password")}
							weakLabel={localizer("Too simple")}
							mediumLabel={localizer("Average complexity")}
							strongLabel={localizer("Complex password")}
						/>
						<label htmlFor="newPassword">{localizer("New password")}</label>
					</FloatLabel>

					<FloatLabel className="col-6">
						<Password
							className="w-full"
							inputId="confirmNewPassword"
							value={confirmNewPassword}
							onChange={(e) => setConfirmNewPassword(e.target.value)}
							toggleMask
						/>
						<label htmlFor="confirmNewPassword">
							{localizer("Confirm new password")}
						</label>
					</FloatLabel>

					<Button className="col-12"
						label={localizer("Change password")}
						onClick={() => changePassword()}
						severity="success"
						loading={isSaving}
					/>
				</div>
			</Card>
		</article>
	);
}
