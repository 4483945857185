import axios from "axios";
import { API_BASE_URL, getToken } from "../utility";

export default function AminController() {
	const token = getToken();
	//> isAdminUser
	this.isAdminUser = async function () {
		let result = false;
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/IsAdmin`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = false;
		}
		return result;
	};
	//> getSummary
	this.getSummary = async function (today) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetSummary?today=${today}`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getUsers
	this.getUsers = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUsers?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> ExportUsers
	this.exportUsers = async function () {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/ExportUsers`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getUserProfile
	this.getUserProfile = async function (userId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUserProfile?userId=${userId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getChildren
	this.getChildren = async function (parentId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetChildren?parentId=${parentId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getMissions
	this.getMissions = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetMissions?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateMission
	this.updateMission = async function (
		missionId,
		missionType,
		title,
		notes,
		data,
		status,
		score,
		publishDate,
		expireDate
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateMission`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					missionId: missionId,
					missionType: missionType,
					title: title,
					notes: notes,
					data: data,
					status: status,
					score: Number(score),
					publishDate: new Date(publishDate),
					expireDate: new Date(expireDate),
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
}
